import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)
const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        userInfo: {
            yhm: JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).yhm : '',
            yhid: JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).yhid : '',
            xm: JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).xm : '',
            xb: JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).xb : '',
            yhlx: JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).yhlx : '',
        },
        token: sessionStorage.getItem('token') || '',
        is_socket:0,
        xm: '',
        fellow: '',
        term: '',
    },
    mutations: {
        // 存储token
        setToken(state, token) {
            state.token = token
        },
        // 存储用户信息
        setInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        // 存储应用信息
        setYycdid(state, yycdid) {
            state.yycdid = yycdid
        },
        setSocket(state, status) {
            state.is_socket = status;
            console.log(state.is_socket)
        },
        setXm(state, xm) {
            state.xm = xm;
        },
        setVip(state, fellow) {
            state.fellow = fellow
        },
        setVipTime(state, term) {
            state.term = term
        },
    },
    actions: {
    },
    getters: {}
})

export default store