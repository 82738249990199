<template>
  <div class="login">
    <div class="bg" id="bg">
      <div class="content" id="content">
        <div class="leftImg" id="leftImg">
          <!-- 背景板阴影 -->
          <img class="boardShadow blue animate__animated animate__fadeInUp" src="../assets/images/login/shadow_blue.png"
               alt=""/>
          <img class="boardShadow pink animate__animated animate__fadeInUp" src="../assets/images/login/shadow_pink.png"
               alt=""/>
          <img class="boardShadow orange animate__animated animate__fadeInUp"
               src="../assets/images/login/shadow_orange.png" alt=""/>
          <img class="boardShadow red animate__animated animate__fadeInUp" src="../assets/images/login/shadow_red.png"
               alt=""/>
          <img class="boardShadow yellow animate__animated animate__fadeInUp"
               src="../assets/images/login/shadow_yellow.png" alt=""/>
          <img class="boardShadow green animate__animated animate__fadeInUp"
               src="../assets/images/login/shadow_green.png" alt=""/>
          <img class="boardShadow purple animate__animated animate__fadeInUp"
               src="../assets/images/login/shadow_purple.png" alt=""/>
          <!-- 基础背景板 -->
          <img class="board blue animate__animated animate__bounceInDown" src="../assets/images/login/board_blue.png"
               alt=""/>
          <img class="board pink animate__animated animate__bounceInDown" src="../assets/images/login/board_pink.png"
               alt=""/>
          <img class="board orange animate__animated animate__bounceInDown"
               src="../assets/images/login/board_orange.png" alt=""/>
          <img class="board red animate__animated animate__bounceInDown" src="../assets/images/login/board_red.png"
               alt=""/>
          <img class="board yellow animate__animated animate__bounceInDown"
               src="../assets/images/login/board_yellow.png" alt=""/>
          <img class="board green animate__animated animate__bounceInDown" src="../assets/images/login/board_green.png"
               alt=""/>
          <img class="board purple animate__animated animate__bounceInDown"
               src="../assets/images/login/board_purple.png" alt=""/>
          <!-- 基础图标 -->
          <img class="shadow robot" src="../assets/images/login/shadow_robot.png" alt=""/>
          <img class="shadow scope" src="../assets/images/login/shadow_scope.png" alt=""/>
          <img class="shadow aerobatRed" src="../assets/images/login/shadow_aerobatRed.png" alt=""/>
          <img class="shadow sound" src="../assets/images/login/shadow_sound.png" alt=""/>
          <img class="shadow aerobatWhite" src="../assets/images/login/shadow_aerobatWhite.png" alt=""/>
          <img class="shadow people" src="../assets/images/login/shadow_people.png" alt=""/>
          <img class="shadow shield" src="../assets/images/login/shadow_shield.png" alt=""/>
          <!-- 阴影图标 -->
          <img class="base robot" src="../assets/images/login/base_robot.png" alt=""/>
          <img class="base scope" src="../assets/images/login/base_scope.png" alt=""/>
          <img class="base aerobatRed" src="../assets/images/login/base_aerobatRed.png" alt=""/>
          <img class="base sound" src="../assets/images/login/base_sound.png" alt=""/>
          <img class="base aerobatWhite" src="../assets/images/login/base_aerobatWhite.png" alt=""/>
          <img class="base people" src="../assets/images/login/base_people.png" alt=""/>
          <img class="base shield" src="../assets/images/login/base_shield.png" alt="" width="84"/>
        </div>
        <div class="rightLogin animate__animated animate__jackInTheBox" style="margin-left:40px" v-show="isShowLogin">
          <div class="h1">单词速记</div>
          <div v-if="showInput">
            <el-input prefix-icon="el-icon-user-solid" v-model="username" placeholder="请输入用户名" class="input"
                      autocomplete="off" auto-complete="new-password"/>
            <el-input prefix-icon="el-icon-unlock" @keyup.enter.native="login" v-model="password"
                      placeholder="请输入密码" type="password" class="input" autocomplete="off"
                      auto-complete="new-password"/>
          </div>
          <div v-if="!showInput">
            <el-input prefix-icon="el-icon-mobile-phone" v-model="myPhone" placeholder="请输入手机号" class="input"
                      autocomplete="off" auto-complete="new-password"/>
            <el-input prefix-icon="el-icon-warning-outline" @keyup.enter.native="login" v-model="myYzm"
                      placeholder="请输入验证码" class="input" autocomplete="off" auto-complete="new-password"/>
          </div>
          <el-button type="primary" round @click="login" style="width: 47%;margin-right: 12px;">登 录</el-button>
          <el-button round style="width: 45%;color: #888888;" @click="showRegistrationDialog"><span>注册</span>
          </el-button>
          <p class="wjmm">
<!--            <el-button @click="getCode" type="text" style="color:white"><span>扫码登录</span></el-button>-->
<!--            <span style="padding:0 50px 0 50px;color: lightblue">|</span>-->
            <el-button v-if="showInput" type="text" style="color:white" @click="textMessage"><span>短信登录</span>
            </el-button>
            <el-button v-if="!showInput" type="text" style="color:white" @click="textMessage"><span>账号登录</span>
            </el-button>
          </p>
        </div>
        <div v-if="showInput && !isShowForm">
          <el-button type="text" style="color:skyblue;position: relative;top: -204px;left:300px " @click="retrieve"
                     :class="{ 'fade-in': showButton }">
            <span>忘记密码?</span></el-button>
        </div>
        <div v-if="!showInput && !isShowForm">
          <el-button style="color: orange; width: 100px; position: relative;top: -204px;left:265px" @click="obtain"
                     :disabled="byTime" :class="{ 'fade-in': showButton }" type="text">
            {{ timeText }}
          </el-button>
        </div>
        <div class="rightWx" v-show="isShowForm">
          <div class="wx_dialog_toiast_delltet" id="login_container"></div>
          <el-button class="wx_dialog_button_delete" type="text" @click="close_wchat_qrcode">账号登录</el-button>
        </div>
      </div>
    </div>
    <!-- 注册对话框 -->
    <el-dialog title="注册账号" :visible.sync="registrationDialogVisible" width="450px"
               :before-close="handleCloseRegistrationDialog">
      <el-form :model="registrationForm" :rules="registrationFormRules" ref="registrationForm" label-width="80px">
        <el-form-item label="姓名" prop="xm">
          <el-input v-model="registrationForm.xm" placeholder="请输入姓名" clearable style="width:280px"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="xb">
          <el-radio-group v-model="registrationForm.xb">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="所属区域" prop="ssqy">
          <SelectArea style="width:280px;" placeholder_text="请选择区域" :value_text="registrationForm.ssqy"
                      @handleChange="areaChange"></SelectArea>
        </el-form-item>
        <el-form-item label="账号" prop="sjh">
          <el-input v-model="registrationForm.sjh" placeholder="请输入手机号" clearable style="width:280px"></el-input>
        </el-form-item>
        <el-form-item prop="yzm">
          <el-input placeholder="请输入验证码" style="width:150px;margin-right:20px" v-model="registrationForm.yzm"
                    clearable></el-input>
          <el-button style="color: orange; border-color: orange;" @click="obtainYzm" :disabled="disableButton">
            {{ buttonText }}
          </el-button>
        </el-form-item>
        <el-form-item label="密码" prop="mm">
          <el-input v-model="registrationForm.mm" placeholder="请输入密码" :type="showPassword ? 'text' : 'password'"
                    style="width:280px;margin-right:10px" autocomplete="new-password"></el-input>
          <svg-icon :icon="showPassword ? 'icon1' : 'icon2' " @click.native="togglePasswordVisibility"
                    style="position: absolute; top:5px ; right: 60px;display: inline-block"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseRegistrationDialog">取 消</el-button>
        <el-button type="primary" @click="register">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 忘记密码-->
    <Forget ref="forget"/>
  </div>
</template>

<script>
import {loginOnline, loginMobile, userOnline, sendOnline} from "@/api/login";
import SelectArea from '../components/area/select_area_schoolManagement.vue'
import Forget from '../components/aboutPassword/forget.vue'
import {queryVipInformation} from '@/api/vip'

export default {
  name: "login",
  components: {SelectArea, Forget},
  data() {
    return {
      showInput: true,
      byTime: false,
      timeText: '获取验证码',
      yzmRes: '',
      myPhone: '',
      myYzm: '',
      showButton: true,
      baseImgData: ["41px", "265px", "56px", "316px", "224px", "51px", "311px"], // 图标下落点top值
      username: "", // 用户名
      password: "", // 密码
      disableButton: false,
      yzmResult: '',
      buttonText: '获取验证码',
      showPassword: false, // 是否显示密码
      registrationDialogVisible: false, // 注册对话框可见性
      isShowForm: false,
      isShowLogin: true,
      registrationForm: {
        xm: "",
        xb: "",
        ssqy: "",
        sjh: "",
        mm: "",
        yhm: '',
        yhlx: 'dcUser',
        yzm: '',
      },
      registrationFormRules: {
        xm: [
          {required: true, message: "请输入姓名", trigger: "blur"}
        ],
        xb: [
          {required: true, message: "请选择性别", trigger: "change"}
        ],
        ssqy: [
          {required: true, message: "请输入所属区域", trigger: "blur"}
        ],
        sjh: [
          {required: true, message: "请输入手机号", trigger: "blur"},
          {pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确，请重新输入", trigger: "blur"}
        ],
        mm: [
          {required: true, message: "请输入密码", trigger: "blur"}
        ],
        yzm: [
          {required: true, message: "请输入验证码", trigger: "blur"}
        ],
      }
    };
  },
  mounted() {
    this.fadeInButton();
    let bases = document.getElementsByClassName("base"); // 所有基础图标
    let shadows = document.getElementsByClassName("shadow"); // 所有阴影图标
    let baseTime = 4000; // 开始下落的时间
    let shadowTime = 4500; // 阴影出现的开始时间
    let downTimeSpace = 500; // 下落的时间间隔
    // 基础图标下落
    for (let i = 0; i < bases.length; i++) {
      setTimeout(() => {
        bases[i].style.top = this.baseImgData[i];
        bases[i].style.opacity = "1";
      }, baseTime + i * downTimeSpace);
    }
    // 阴影图标出现
    for (let i = 0; i < shadows.length; i++) {
      setTimeout(() => {
        shadows[i].style.opacity = "1";
      }, shadowTime + i * downTimeSpace);
    }
  },
  methods: {
    textMessage() {
      this.showInput = !this.showInput
    },
    async obtain() {
      if (!this.myPhone) {
        return this.$message.error("请先输入手机号");
      }
      const {resultCode, result} = await sendOnline({phone: this.myPhone})
      if (resultCode == 200) {
        this.yzmRes = result
        this.$message.success('获取成功')
      } else {
        this.$message.error('获取失败')
      }

      // 禁用按钮并设置倒计时
      this.byTime = true;
      let seconds = 60;
      const countDown = setInterval(() => {
        seconds--;
        if (seconds === 0) {
          clearInterval(countDown);
          this.byTime = false;
          this.timeText = '获取验证码';
        } else {
          this.timeText = `${seconds} 秒后重新获取`;
        }
      }, 1000);
    },
    fadeInButton() {
      setTimeout(() => {
        this.showButton = false;
      }, 600);
    },
    retrieve() {
      this.$refs.forget.dialogVisible = true
    },
    close_wchat_qrcode() {
      this.isShowForm = false
      this.isShowLogin = true
      this.showButton = true
      setTimeout(() => {
        this.showButton = false
      }, 500)
    },
    getCode() {
      this.isShowForm = true;
      this.isShowLogin = false;
      new window.WxLogin({
        self_redirect: false,
        id: "login_container",
        appid: "wx19ee831441ad4be5",
        scpoe: "snsapi_login",
        redirect_uri: "https://aiyb.fefsfc.com",
        state: Math.random(),
        style: "white",
        href: "data:text/css;base64,Lyogd3hsb2dpbi5jc3MgKi8NCi5pbXBvd2VyQm94IC50aXRsZSwgLmltcG93ZXJCb3ggLmluZm97DQogIGRpc3BsYXk6IG5vbmU7DQp9DQoNCi5pbXBvd2VyQm94IC5xcmNvZGV7DQogIG1hcmdpbi10b3A6IDIwcHg7DQp9",
      })
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async obtainYzm() {
      if (!this.registrationForm.sjh) {
        return this.$message.error("请先输入手机号");
      }
      const {resultCode, result} = await sendOnline({phone: this.registrationForm.sjh})
      if (resultCode == 200) {
        this.yzmResult = result
        this.$message.success('获取成功')
      } else {
        this.$message.error('获取失败')
      }
      // 禁用按钮并设置倒计时
      this.disableButton = true;
      let seconds = 60;
      const countDown = setInterval(() => {
        seconds--;
        if (seconds === 0) {
          clearInterval(countDown);
          this.disableButton = false;
          this.buttonText = '获取验证码';
        } else {
          this.buttonText = `${seconds} 秒后重新获取`;
        }
      }, 1000);
    },
    // 所属区域change事件
    areaChange(val) {
      this.$set(this.registrationForm, 'ssqy', val.area_value)
    },
    async login() {
      if (this.showInput) {
        const {username, password} = this;

        if (!username) {
          return this.$message.error("用户名不能为空");
        }

        if (!password) {
          return this.$message.error("密码不能为空");
        }

        try {
          const res = await loginOnline({yhm: username, mm: password, yhlx: 'dcUser'});

          if (res.success) {
            const {yhm, yhid, xm, xb, yhlx, userToken, sjh} = res.result[0];
            const userInfo = {yhm, yhid, xm, xb, yhlx};
            this.$store.commit('setXm', xm);
            sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
            sessionStorage.setItem("token", userToken);
            sessionStorage.setItem("sjh", sjh);
            sessionStorage.setItem("yhid", yhid);
            this.$store.commit("setInfo", userInfo);
            this.$store.commit("setToken", userToken);
            const vipRes = await queryVipInformation({yhid: sessionStorage.getItem('yhid')});
            if (vipRes && vipRes.result !== undefined && vipRes.result !== null && Object.keys(vipRes.result).length !== 0) {
              const vipResult = vipRes.result;
              if (Object.keys(vipResult).length !== 0) {
                const time = vipResult.sxsj
                this.$store.commit('setVip', true);
                this.$store.commit('setVipTime', time);
              }
            } else {
              this.$store.commit('setVip', false);
            }
            this.$router.replace({name: "home"});
          } else {
            this.$message.error(res.resultDesc);
          }
        } catch (error) {
          console.error(error);
          this.$message.error("登录失败");
        }
      } else {
        if (!this.myPhone) {
          return this.$message.error("手机不能为空");
        }
        if (!this.myYzm) {
          return this.$message.error("验证码不能为空");
        }
        if (this.myYzm != this.yzmRes) {
          return this.$message.error('请输入正确的验证码')
        }
        const res = await loginMobile({yhm: this.myPhone, yhlx: 'dcUser'})
        if (res.success) {
          const {yhm, yhid, xm, xb, yhlx, userToken, sjh} = res.result[0];
          const userInfo = {yhm, yhid, xm, xb, yhlx};
          this.$store.commit('setXm', xm);
          sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
          sessionStorage.setItem("token", userToken);
          sessionStorage.setItem("sjh", sjh);
          sessionStorage.setItem("yhid", yhid);
          this.$store.commit("setInfo", userInfo);
          this.$store.commit("setToken", userToken);
          const vipRes = await queryVipInformation({yhid: sessionStorage.getItem('yhid')});
          if (vipRes && vipRes.result !== undefined && vipRes.result !== null && Object.keys(vipRes.result).length !== 0) {
            const vipResult = vipRes.result;
            if (Object.keys(vipResult).length !== 0) {
              const time = vipResult.sxsj
              this.$store.commit('setVip', true);
              this.$store.commit('setVipTime', time);
            }
          } else {
            this.$store.commit('setVip', false);
          }
          this.$router.replace({name: "home"});
        } else {
          this.$message.error(res.resultDesc)
        }
      }

    },
    // 显示注册对话框
    showRegistrationDialog() {
      this.registrationDialogVisible = true;
    },
    // 注册
    register() {
      this.registrationForm.yhm = this.registrationForm.sjh
      this.$refs.registrationForm.validate(valid => {
        if (valid) {
          if (this.registrationForm.yzm != this.yzmResult) {
            return this.$message.error('请输入正确的验证码')
          }
          userOnline({user: this.registrationForm})
              .then(res => {
                if (res.resultCode == 200) {
                  this.$message.success('注册成功')
                  this.handleCloseRegistrationDialog();
                } else {
                  this.$message.error(res.resultDesc)
                }
              })
              .catch(error => {
                this.$message.error("注册失败，请重试");
              });
        } else {
          return false;
        }
      });
    },
    // 关闭注册对话框
    handleCloseRegistrationDialog() {
      this.$refs.registrationForm.resetFields();
      this.registrationDialogVisible = false;
    }
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 640px) {
  #bg {
    background: url("../assets/images/login/yidong.png") no-repeat;
    background-size: 100% 100%;
  }
  #leftImg {
    display: none;
  }
  #rightLogin {
    display: block !important;
  }
  .animate__animated {
    display: none;
  }
  #content {
    width: 100%;
    height: 100%;
    position: relative;
  }
  #rightLogin {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#rightLogin {
  display: none;
}

.login {
  .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("../assets/images/login/banner2.png") no-repeat;
    background-size: 100% 100%;
    overflow: hidden;

    .content {
      width: 1350px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .leftImg {
        float: left;
        position: relative;
        width: 930px;
        height: 510px;

        .base {
          position: absolute;
          transition: 1s;
          opacity: 0;
        }

        .shadow {
          position: absolute;
          transition: 1s;
          opacity: 0;
        }

        .board {
          position: absolute;
        }

        .boardShadow {
          position: absolute;
        }

        .boardShadow.blue {
          top: 87px;
          left: 71px;
          -webkit-animation-delay: 1s; //延迟
          animation-delay: 1s;
        }

        .boardShadow.pink {
          top: 292px;
          left: 0px;
          -webkit-animation-delay: 1.3s; //延迟
          animation-delay: 1.3s;
        }

        .boardShadow.orange {
          top: 46px;
          left: 448px;
          -webkit-animation-delay: 1.6s; //延迟
          animation-delay: 1.6s;
        }

        .boardShadow.red {
          top: 301px;
          left: 194px;
          -webkit-animation-delay: 2s; //延迟
          animation-delay: 2s;
        }

        .boardShadow.yellow {
          top: 214px;
          left: 459px;
          -webkit-animation-delay: 2.3s; //延迟
          animation-delay: 2.3s;
        }

        .boardShadow.green {
          top: 133px;
          left: 622px;
          -webkit-animation-delay: 2.6s; //延迟
          animation-delay: 2.6s;
        }

        .boardShadow.purple {
          top: 352px;
          left: 550px;
          -webkit-animation-delay: 3s; //延迟
          animation-delay: 3s;
        }

        .board.blue {
          top: 61px;
          left: 71px;
          -webkit-animation-delay: 1s; //延迟
          animation-delay: 1s;
        }

        .board.pink {
          top: 265px;
          left: 0;
          -webkit-animation-delay: 1.3s; //延迟
          animation-delay: 1.3s;
        }

        .board.orange {
          top: 20px;
          left: 448px;
          -webkit-animation-delay: 1.6s; //延迟
          animation-delay: 1.6s;
        }

        .board.red {
          top: 275px;
          left: 194px;
          -webkit-animation-delay: 2s; //延迟
          animation-delay: 2s;
        }

        .board.yellow {
          top: 189px;
          left: 459px;
          -webkit-animation-delay: 2.3s; //延迟
          animation-delay: 2.3s;
        }

        .board.green {
          top: 107px;
          left: 622px;
          -webkit-animation-delay: 2.6s; //延迟
          animation-delay: 2.6s;
        }

        .board.purple {
          top: 326px;
          left: 550px;
          -webkit-animation-delay: 3s; //延迟
          animation-delay: 3s;
        }

        .base.robot {
          top: -100%;
          left: 234px;
        }

        .base.aerobatRed {
          top: -100%;
          left: 530px;
        }

        .base.sound {
          top: -100%;
          left: 346px;
        }

        .base.aerobatWhite {
          top: -100%;
          left: 560px;
        }

        .base.people {
          top: -100%;
          left: 703px;
        }

        .base.shield {
          top: -100%;
          left: 642px;
        }

        .base.scope {
          top: -100%;
          left: 82px;
        }

        .shadow.robot {
          top: 201px;
          left: 236px;
        }

        .shadow.aerobatRed {
          top: 97px;
          left: 530px;
        }

        .shadow.scope {
          top: 326px;
          left: 82px;
        }

        .shadow.sound {
          top: 388px;
          left: 346px;
        }

        .shadow.aerobatWhite {
          top: 255px;
          left: 560px;
        }

        .shadow.people {
          top: 173px;
          left: 723px;
        }

        .shadow.shield {
          top: 388px;
          left: 642px;
        }
      }

      .rightLogin {
        background-color: #66c1ff;
        float: left;
        width: 360px;
        padding: 0 25px 50px 25px;

        .wjmm {
          padding: 15px 0 0 3px;
          text-align: center;
          width: 100%;
          color: white;

          span {
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
          }
        }

        .h1 {
          color: #fff;
          font-size: 40px;
          font-weight: bold;
          text-align: center;
          padding-bottom: 30px;
          padding-top: 50px;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }

        .h2 {
          color: #fff; // #75b7f7;
          font-size: 25px;
          text-align: center;
          padding-bottom: 40px;
        }

        .input {
          padding-bottom: 20px;
        }

        .btn {
          width: 100%;
          margin-top: 10px;
        }
      }

      .rightWx {
        float: left;
        width: 280px;
        margin-left: 50px;

        .btn {
          width: 100%;
          margin-top: 10px;
        }

        .wx_dialog_toiast_delltet {
          width: 300px;
          height: 400px;
          background: #606266;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .wx_dialog_button_delete {
          margin-left: 125px;
          color: white;
          font-size: 15px;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.login {
  /deep/ .el-button--primary {
    background-color: #2ecbff;
    border-color: #2ecbff;
  }
}

/deep/ .el-input__suffix {
  padding-bottom: 50px;
}

/deep/ .el-input__icon {
  height: 67%;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s;
}

.fade-in.show {
  opacity: 1;
}

/deep/ .el-dialog__header {
  background-color: #2ecbff;
  color: #fff;
  padding: 15px 15px 14px;
}

/deep/ .el-dialog__title {
  color: #fff;
}

/deep/ .el-dialog__close {
  color: #fff;
}

.dialog-footer {
  padding: 10px;
  text-align: right;
  background-color: #f0f2f5;
}

/deep/ .el-dialog__footer {
  padding: 0;
}
</style>


