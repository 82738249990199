
let baseURL = '/api'
const url = window.location.href
let imgUrl
let browserUrl // 退出登录
if (url.indexOf('online') !== -1) {
    imgUrl = 'static'
} else {
    imgUrl = '/static'
}
let socketUrl
  // 开发环境
if(url.indexOf('192.168.6.186') !== -1 || url.indexOf('devonline.fefsfc.com') !== -1){
  browserUrl = 'http://192.168.6.186:8858/#/usercenter/login'
  socketUrl = 'ws://192.168.6.186:8859/api/8710/ScreenCtl'

   // 测试环境
} else if (url.indexOf('192.168.6.178') !== -1) {
  browserUrl = 'http://192.168.6.178/#/usercenter/login'
  socketUrl = 'ws://192.168.6.178:8859/api/8710/ScreenCtl'

  // 准生产环境
} else if (url.indexOf('uat-word.fefsfc.com') !== -1) {
  browserUrl = 'https://uat-user.fefsfc.com:6443/#/usercenter/login'
  socketUrl = 'wss://uat-word.fefsfc.com:6443/api/8710/ScreenCtl'

   // 生产环境
} else if(url.indexOf('word.fefsfc.com') !== -1){
  browserUrl = 'https://user.fefsfc.com/#/usercenter/login'
  socketUrl = 'wss://word.fefsfc.com/api/8710/ScreenCtl'

   // 本地
} else if(url.indexOf('150') !== -1){
  browserUrl = 'http://192.168.6.150:8099/#/usercenter/login'
  socketUrl = 'ws://192.168.6.186:8859/api/8710/ScreenCtl'
} else {
  browserUrl = 'http://192.168.6.174:8099/#/usercenter/login'
  socketUrl = 'ws://192.168.6.186:8859/api/8710/ScreenCtl'
}

export { imgUrl, baseURL, socketUrl, browserUrl }