// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from "./store/index"
import ElementUI from 'element-ui'
import SvgIcon from './components/svgIcon/svgIcon.vue'; // 请确保路径正确
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/index.scss'
import animated from 'animate.css'
import Toasts from './components/student/Toast'
import Bus from '@/utils/bus.js'
import utils from '@/utils/utils.js'
import '@/utils/filter.js'
//  token过期退出登录
// import './permission'
const Toast = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const ToastConstructor = Vue.extend(Toasts)
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new ToastConstructor()
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    // document.body.appendChild(instance.$el)

    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype.$lock = (msg) => {
      if(msg == 'on'){
        document.body.appendChild(instance.$el)
      } else if(msg == 'off'){
        if(document.querySelector('.transition')){
          document.body.removeChild(instance.$el)
        }
      } else {
        //
      }
    }
  }
}
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(animated)
Vue.use(Toast)
Vue.component('svg-icon', SvgIcon);
Vue.prototype.$Bus = Bus
Vue.prototype.$utils = utils
/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
