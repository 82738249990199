const routes = [
    {
        path: '/',
        name: 'student',
        component: () => import('@/views/student/index.vue'),
        meta: {title: "学生端主页"},
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('@/views/student/home.vue'),
                meta: {title: "主页"}
            },
            {
                path: '/studyway',
                name: 'studyway',
                component: () => import('@/views/student/studyway/studyway.vue'),
                meta: {title: "学习之旅"}
            },
            {
                path: '/books',
                name: 'books',
                component: () => import('@/views/student/studyway/books.vue'),
                meta: {title: "书籍页"}
            },
            {
                path: '/units',
                name: 'units',
                component: () => import('@/views/student/studyway/units.vue'),
                meta: {title: "单元页"}
            },
            {
                path: '/wordcontent',
                name: 'wordcontent',
                component: () => import('@/views/student/studentcontent/wordcontent.vue'),
                meta: {title: "单元页"}
            },
            {
                path: '/study',
                name: 'study',
                component: () => import('@/views/student/studentcontent/study.vue'),
                meta: {title: "单元页"}
            },
            // 学习之旅测试页
            {
                path: '/testpage',
                name: 'testpage',
                component: () => import('@/views/student/studentcontent/testpage.vue'),
                meta: {title: "单元页"}
            },
            {
                path: '/secharts',
                name: 'secharts',
                component: () => import('@/views/student/echarts/echarts.vue'),
                meta: {title: "学情分析"}
            },
            {
                path: '/wordbook',
                name: 'wordbook',
                component: () => import('@/views/student/wordbook/wordbook.vue'),
                meta: {title: "单词本"},
                children: [
                    {
                        path: 'question',
                        name: 'question',
                        component: () => import('@/views/student/wordbook/question.vue'),
                        meta: {title: '错题本'}
                    },
                    {
                        path: 'collection',
                        name: 'collection',
                        component: () => import('@/views/student/wordbook/collection.vue'),
                        meta: {title: '我的收藏'}
                    },
                    {
                        path: 'AIhome',
                        name: 'AIhome',
                        component: () => import('@/views/student/wordbook/AIhome.vue'),
                        meta: {title: 'AI智能'}
                    }
                ]
            },
            {
                path: '/wrongquestion',
                name: 'wrongquestion',
                component: () => import('@/views/student/wordbook/wrongquestion.vue'),
                meta: {title: "错题库"}
            },
            {
                path: '/personalCenter',
                name: 'personalCenter',
                component: () => import('@/views/student/personal/personalCenter.vue'),
                meta: {title: "个人中心"},
                children: [
                    {
                        path: 'coins',
                        name: 'coins',
                        meta: {title: "我的金币"},
                        component: () => import('@/views/student/personal/coins.vue'),
                    },
                    {
                        path: 'test',
                        name: 'test',
                        meta: {title: "我的金币"},
                        component: () => import('@/views/student/personal/test.vue'),
                    },
                    {
                        path: 'order',
                        name: 'order',
                        meta: {title: "我的订单"},
                        component: () => import('@/views/student/personal/orderList.vue'),
                    },
                    // {
                    //   path: '/class/classStudent',
                    //   name: 'classStudent',
                    //   meta: { title: "班级学生" },
                    //   component: () => import('@/views/teacher/class/classStudent.vue'),
                    // },
                ],
            },
            {
                path: '/setUpPage',
                name: 'setUpPage',
                meta: {title: "设置"},
                component: () => import('@/views/student/setUpPage/setUpPage.vue'),
                children: [
                    {
                        path: 'information',
                        name: 'information',
                        meta: {title: "基本资料"},
                        component: () => import('@/views/student/setUpPage/information.vue')
                    },
                    {
                        path: 'commonproblem',
                        name: 'commonproblem',
                        meta: {title: "常见问题"},
                        component: () => import('@/views/student/setUpPage/commonproblem.vue')
                    },
                    {
                        path: 'opinion',
                        name: 'opinion',
                        meta: {title: "意见反馈"},
                        component: () => import('@/views/student/setUpPage/opinion.vue')
                    },
                ]
            },
            // 测试
            {
                path: '/totaltest',
                name: 'totaltest',
                component: () => import('@/views/student/test/totaltest.vue'),
                meta: {title: "测试"}
            },

        ],
    }
]
export default routes