<template>
  <el-dialog
    title="忘记密码"
    :visible.sync="dialogVisible"
    width="400px"
    :before-close="handleClose"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="formData" :rules="formRules" label-width="100px">
        <el-form-item label="账号" prop="yhm" style="padding-bottom: 5px">
          <el-input v-model="formData.yhm" autocomplete="off" placeholder="请输入手机号" clearable></el-input>
        </el-form-item>
        <el-form-item prop="yzm">
          <el-input placeholder="请输入验证码" style="width:140px;margin-right: 10px" v-model="formData.yzm"
                    clearable></el-input>
          <el-button style="color: orange; border-color: orange;width: 135px" @click="obtainYzm"

                     :disabled="disableButton">
            {{ buttonText }}
          </el-button>
        </el-form-item>
        <el-form-item label="新密码" prop="mm" style="padding-bottom: 5px">
          <el-input v-model="formData.mm" autocomplete="new-password" placeholder="请输入密码"
                    :type="showPassword ? 'text' : 'password'"></el-input>
          <svg-icon :icon="showPassword ? 'icon1' : 'icon2' " @click.native="togglePasswordVisibility"
                    style="position: absolute; top:5px ; right: 10px;display: inline-block"/>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {sendOnline, forgetPassword, alterPassword} from "@/api/login";
export default {
  data() {
    return {
      dialogVisible: false,
      buttonText: '获取验证码',
      disableButton: false,
      showPassword: false, // 是否显示密码
      yzmResult: '',
      formData: {
        yhm: '',
        mm: '',
        yzm: '',
        yhlx: 'dcUser',
        sjh: ''
      },
      formRules: {
        yhm: [
          {required: true, message: '请输入手机号', trigger: 'blur'}
        ],
        mm: [
          {required: true, message: '请输入新密码', trigger: 'blur'}
        ],
        yzm: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async obtainYzm() {
      if (!this.formData.yhm) {
        return this.$message.error("请先输入手机号");
      }
      const {resultCode, result} = await sendOnline({phone: this.formData.yhm})
      if (resultCode == 200) {
        this.yzmResult = result
        this.$message.success('获取成功')
      } else {
        this.$message.error('获取失败')
      }

      // 禁用按钮并设置倒计时
      this.disableButton = true;
      let seconds = 60;
      const countDown = setInterval(() => {
        seconds--;
        if (seconds === 0) {
          clearInterval(countDown);
          this.disableButton = false;
          this.buttonText = '获取验证码';
        } else {
          this.buttonText = `${seconds} 秒后重新获取`;
        }
      }, 1000);
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.dialogVisible = false
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        this.formData.sjh = this.formData.yhm
        if (valid) {
          if (this.formData.yzm != this.yzmResult) {
            return this.$message.error('请输入正确的验证码')
          }
          forgetPassword(this.formData)
            .then(res => {

              if (res.resultCode == 200) {

                if (res.result != null) {
                  let param = {
                    appIdList: [],
                    classIdList: [],
                    roleIdList: [],
                    studentsIdList: [],
                    tenantsIdList: [],
                    user: {yhid: res.result.yhid, mm: this.formData.mm}
                  }
                  alterPassword(param).then((res) => {
                    if (res.resultCode === 200) {
                      this.dialogVisible = false;
                      this.$refs.form.resetFields();
                      this.$message.success('修改成功')
                    } else {
                      this.$message.error('修改失败')
                    }
                  })
                } else {
                  this.$message.error('该用户还未注册，请先注册')
                }
              } else {
                this.$message.error(res.resultDesc)
              }
            })
            .catch(error => {
              this.$message.error("获取失败，请重试");
            });

        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-content {
  text-align: center;
  margin-left: -30px;
}

.dialog-footer {
  padding: 10px;
  text-align: right;
  background-color: #f0f2f5;
  border-radius: 10px;
}

/deep/ .el-dialog__footer {
  padding: 0;
}


</style>
