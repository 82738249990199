import axios from 'axios'
import store from '../store'
import Vue from 'vue'
import {MessageBox} from 'element-ui';

let isErrorShown = false; // 用于跟踪是否已经显示错误消息
import { baseURL } from '@/utils/index'
import { browserUrl } from '@/utils/index.js'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.headers.token = sessionStorage.getItem('token')
const http = axios.create({
  baseURL: '',
  // cache: false,
  withCredentials: true,
  headers: {
    // 'Content-Type': 'application/json;charset=utf-8'
  }
})
http.interceptors.request.use(
  config => {
    if (store.state.token) {
      config.headers.token = store.state.token
      config.headers.yhid = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')).yhid : ''
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
http.interceptors.response.use(
  response => {
    if (response.resultCode === 200) {
      // 处理登录成功的逻辑
      return response;
    } else if (response.resultCode === -1 || response.resultCode === 401) {
      window.location.href = '/login'; // 重定向到登录页
      throw new Error('Token expired');
    } else {
      // 处理其他情况的逻辑
      return response;
    }
  },
  error => {
    if(error.response.status ===401){
      if (!isErrorShown) {
        isErrorShown = true;
        MessageBox.alert(error.response.data.resultDesc, '提示', {
          confirmButtonText: '确定',
          showClose: false,
          type: 'warning',
          callback: action => {
            window.location.href = '/login';
          }
        });
      }
    }
    return Promise.reject(error);
  }
);

async function apiAxios(method, url, params, response) {
  try {
    const res = await http({
      method: method,
      url: url,
      data: method === 'POST' || method === 'PUT' ? params : null,
      params: method === 'GET' || method === 'DELETE' ? params : null
    })
    return new Promise((resolve, reject) => {
      if (res.data.resultCode == 200) {
        resolve(res.data)
      } else if (res.data.resultCode == -1 || res.data.resultCode == 401){
        window.location.href = browserUrl
        //
      }else{
        resolve(res.data)
        // this.$message.error(res.data.resultDesc)
      }

    })
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject(err)
    })
  }
}

export default {
  get: function (url, params, response) {
    return apiAxios('GET', url + '?t=' + new Date().getTime(), params, response)
  },
  post: function (url, params, response) {
    return apiAxios('POST', url + '?t=' + new Date().getTime(), params, response)
  },
  put: function (url, params, response) {
    return apiAxios('PUT', url + '?t=' + new Date().getTime(), params, response)
  },
  delete: function (url, params, response) {
    return apiAxios('DELETE', url + '?t=' + new Date().getTime(), params, response)
  }
}
Vue.prototype.$axios = axios