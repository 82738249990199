<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import {queryVipInformation} from '@/api/vip';
export default {
  name: 'App',
  // async created() {
  //   if (sessionStorage.getItem("token")) {
  //     try {
  //       const vipRes = await queryVipInformation({yhid: sessionStorage.getItem('yhid')});
  //       if (vipRes && vipRes.result !== undefined && vipRes.result !== null && Object.keys(vipRes.result).length !== 0) {
  //         const vipResult = vipRes.result;
  //         if (Object.keys(vipResult).length !== 0) {
  //           const time = vipResult.sxsj
  //           this.$store.commit('setVip', true);
  //           this.$store.commit('setVipTime', time);
  //         }
  //       } else {
  //         this.$store.commit('setVip', false);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // }
}
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
