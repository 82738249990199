<template>
  <div class="select-area-boxTwo">
    <el-cascader
      ref="myCascader"
      :props="area_list"
      :placeholder="placeholder_text"
      v-model="value"
      @change="selectChange"
    ></el-cascader>
  </div>
</template>
<script>
let id = 0;
import { getAreaList } from "@/api/login";
export default {
  components: {},
  props: ["value_text", "placeholder_text", "disabled_text"],
  data() {
    return {
      dot: {}, //赋值节点
      value: '',
      area_list: {
        label: "dqmc",
        value: "dqbm",
        expandTrigger: "hover",
        lazy: true,
        lazyLoad(node, resolve) {
          if (node.label > 0) {
            if (!node.hasChildren) {
              return false;
            }
          }
          setTimeout(() => {
            getAreaList({ sjbm: node.value ? node.value : 0 }).then((res) => {
              const node = res.result;
              resolve(node);
            });
          }, 1);
        },
      },
    };
  },

  mounted() {
    this.init();
  },
  watch: {
    value_text: function () {
      console.log('222')
      this.init();
    },
  },
  methods: {
    // 数据初始化展示
    init() {
      this.dot = document
        .querySelectorAll(".select-area-boxTwo")[0]
        .getElementsByClassName("el-input__inner")[0];

      if (!this.value_text) {
        this.dot.value = "";
      } else {
        this.dot.value = this.value_text;
      }
      this.$refs.myCascader.$refs.panel.clearCheckedNodes();
    },

    selectChange(value) {
      if(value.length==0){
        return false
      }
      const area_bm = value[value.length - 1];
      const aaa = this.$refs.myCascader.getCheckedNodes()[0].pathLabels;
      const area_value = aaa.join("/");
      this.$emit("handleChange", { area_value: area_value, area_bm: area_bm, value: value });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-area-boxTwo {
  width: 100%;
  /deep/.el-select {
    width: 100%;
  }
  /deep/.el-input {
    width: 100%;
  }
  /deep/.el-cascader {
    display: block;
  }
}
.select-area-boxTwo >>> .el-cascader {
  display: block;
}
</style>