<template>
  <transition v-show="visible" name="fade" class="fadeclass">
    <div class="transition">
      <div class="lock" v-if="flag">
        <img src="../../assets/images/student/lock.png" alt="">
        <p>当前为休息时间, &ensp; 请爱护眼睛！</p>
        <div class="btn">去做<el-button  @click="lookImg" type="text">眼部训练</el-button></div>
      </div>
      <div class="demo-image__preview" v-if="!flag">
        <span class="left" @click="left">
          <i class="el-icon-arrow-left"></i>
        </span>
        <span class="right" @click="right">
          <i class="el-icon-arrow-right"></i>
        </span>
        <span class="close" @click="close">
          <i class="el-icon-close"></i>
        </span>
        <img class="el-image" :src="imgUrl" alt="">
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      srcList: [
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练1.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练2.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练3.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练4.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练5.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练6.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练7.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练8.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练9.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练10.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练11.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练12.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练13.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练14.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练15.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练16.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练17.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练18.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练19.gif',
        'https://pk-words.oss-cn-shanghai.aliyuncs.com/vision/视觉训练20.gif',
      ],
      imgUrl:'',
      index:0,
      flag:true
    }
  },
  methods:{
    //  ------------------ 去做眼部训练
    lookImg(){
      this.randomImg()
      this.flag = false
    },
    //  --------------------退出眼部训练
    close(){
      this.flag = true
    },
    //  --------------------------左右切换
    left(){
      this.randomImg()
    },
    right(){
      this.randomImg()
    },
    //  -----------------------------随机展示图片
    randomImg(){
      this.index = Math.floor(Math.random() * 19) + 1
      this.imgUrl = this.srcList[this.index]
    }
  },
}
</script>

<style scoped lang="scss">
.transition {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.8);
  z-index: 5001;
  .lock{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    img{
      display: block;
      width: 12.5vw;
      height: 17vw;
      margin: auto;
    }
    p{
      font-size: 3vw;
      color: #fff;
      text-align: center;
      margin-top: 30px;
      // font-weight: bold;
    }
    .btn{
      margin-top: 20px;
      font-size: 2vw;
      color: #fff;
      text-align: center;
      /deep/.el-button{
        font-size: 2vw;
      }
    }
  }
  .demo-image__preview{
    position: absolute;
    top: 5%;
    left: 5%;
    right: 5%;
    bottom: 5%;
    z-index: 5002;
    span{
      display: block;
      width: 44px;
      height: 44px;
      color: #fff;
      background-color: #606266;
      border-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      opacity: .8;
      cursor: pointer;
      box-sizing: border-box;
    }
    .left{
      position: absolute;
      top: 50%;
      left: 0;
    }
    .right{
      position: absolute;
      top: 50%;
      right: 0;
    }
    .close{
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
</style>
<style lang="scss">
.transition{
  .el-image{
    display: block;
    width: 80vw;
    height: 40vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
  }
}
</style>