import Vue from "vue";
import Router from "vue-router";
import stuRouters from "./router-stu";
import login from "@/views/login.vue";
import { Notification } from "element-ui";
Vue.use(Router);

let router = new Router({
  mode: "hash",
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      name: "login",
      component: login,
      display: false,
    },
    ...stuRouters,
  ],
});
//路由拦截器
const whiteRouter = ["/login", "/"];
router.beforeEach((to, from, next) => {
  if (whiteRouter.indexOf(to.path) !== -1) {
    next();
  } else if (sessionStorage.getItem("token")) {
    next();
  } else {
    Notification({
      title: "Error",
      center: true,
      message: "请先登录",
      type: "error",
      customClass: "z1000",
    });
    next("/login");
  }
});
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
router.afterEach((to, from, next) => {});
export default router;
